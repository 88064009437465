<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-create-group" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-bank me-1 text-primary"></i> Add Bank Account
           </h5>
        </template>
        <div class="row align-items-top">
            <div class="mb-3 col-12">
              <label class="mb-1 me-2">Bank Name</label>
                <multiselect v-if="banksList.length" v-model="selectedBanks"  @input="updateBankName" :options="banksList" label="name" class="font-size-15" group-label="category" group-values="list" placeholder="Select Bank" track-by="name" :close-on-select="true" :allow-empty="false" deselect-label="" select-label="" :show-on-select="true" :disabled="modalLoading || formData.bank_name == ''" :class="{'is-invalid':modalSubmit && $v.formData.bank_name.$error }"
                >
                  <span slot="noResult">Oops! No bank found. </span>
                </multiselect>
                <div v-if="modalSubmit && $v.formData.bank_name.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.bank_name.required">Receiving Bank is required.</span>
                  <span v-else-if="!$v.formData.bank_name.equalDefault">Receiving Bank is required.</span>
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>Bank Account Name</label>
              <input
                v-model.trim="formData.bank_account_name"
                type="text"
                class="form-control"
                placeholder="Bank Account Name"
                name="Bank Account Name"
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.bank_account_name.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.bank_account_name.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.bank_account_name.required"
                    >Bank Account Name is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12" v-if="selectedBanks.name!=='USDT'">
              <label>Bank Account No.</label>
              <input
                v-model.trim="formData.bank_account_no"
                @input="inputNumberOnly($event, 'formData', 'bank_account_no')"
                type="tel"
                class="form-control"
                placeholder="Bank Account No."
                name="Bank Account No."
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.bank_account_no.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.bank_account_no.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.bank_account_no.required"
                    >Bank Account No. is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12" v-else>
              <label>Bank Account No. (Address)</label>
              <input
                v-model.trim="formData.bank_account_no"
                type="text"
                class="form-control"
                placeholder="Bank Account No."
                name="Bank Account No."
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.bank_account_no.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.bank_account_no.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.bank_account_no.required"
                    >Bank Account No. is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>IFSC Code</label>
              <input
                v-model.trim="formData.ifsc_code"
                type="text"
                class="form-control"
                placeholder="IFSC Code"
                name="IFSC Code"
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.ifsc_code.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.ifsc_code.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.ifsc_code.required"
                    >IFSC Code is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>Current Bank Balance</label>
              <input
                v-model.trim="formData.current_bank_balance"
                @input="inputNumberOnly($event, 'formData', 'current_bank_balance')"
                type="text" 
                inputmode="decimal"
                class="form-control"
                placeholder="Current Bank Balance"
                name="Current Bank Balance"
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.current_bank_balance.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.current_bank_balance.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.current_bank_balance.required"
                    >Current Bank Balance is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>Transaction Limit</label>
              <input
                v-model.trim="formData.transaction_limit"
                @input="inputNumberOnly($event, 'formData', 'transaction_limit')"
                type="text" 
                inputmode="decimal"
                class="form-control"
                placeholder="Transaction Limit"
                name="Transaction Limit"
                :class="{
                'is-invalid':
                modalSubmit && $v.formData.transaction_limit.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formData.transaction_limit.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formData.transaction_limit.required"
                    >Transaction Limit is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>Remarks</label>
              <div class="input-group">
                <input
                  v-model.trim="formData.remarks"
                  type="text"
                  class="form-control"
                  placeholder="Remarks"
                  name="Remarks"
                />
                <b-dropdown variant="info" right>
                  <template v-slot:button-content>
                    General Remark
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item
                    href="javascript:void(0)"
                    :value="value"
                    @click="formData.remarks=value"
                    v-for="(value, index) in remarksList"
                    :key="index"
                    >{{ value }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
            <div class="col-12">
                <hr>
                <div class="d-grid gap-2 d-md-block text-end">
                  <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-create-group'].hide()">Cancel</button>
                  <button type="button" class="btn fw-medium btn-info" @click="addRequest" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Adding...</span>
                  <span v-else> Add Bank Account </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                  </button>
                </div>
            </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-edit-group" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-bank me-1 text-primary"></i> Edit Bank Account
           </h5>
        </template>
        <div class="row align-items-top">
            <div class="mb-3 col-12">
              <label class="mb-1 me-2">Bank Name</label>
                <multiselect v-if="banksList.length" v-model="selectedBanks"  @input="updateBankName" :options="banksList" label="name" class="" group-label="category" group-values="list" placeholder="Select Bank" track-by="name" :close-on-select="true" :allow-empty="false" deselect-label="" select-label="" :show-on-select="true" :disabled="modalLoading || formEditData.bank_name == ''" :class="{'is-invalid':modalSubmit && $v.formEditData.bank_name.$error }"
                >
                  <span slot="noResult">Oops! No bank found. </span>
                </multiselect>
                <div v-if="modalSubmit && $v.formEditData.bank_name.$error" class="invalid-feedback">
                  <span v-if="!$v.formEditData.bank_name.required">Receiving Bank is required.</span>
                  <span v-else-if="!$v.formEditData.bank_name.equalDefault">Receiving Bank is required.</span>
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>Bank Account Name</label>
              <input
                v-model.trim="formEditData.bank_account_name"
                type="text"
                class="form-control"
                placeholder="Bank Account Name"
                name="Bank Account Name"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.bank_account_name.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.bank_account_name.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.bank_account_name.required"
                    >Bank Account Name is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12" v-if="selectedBanks.name!=='USDT'">
              <label>Bank Account No.</label>
              <input
                v-model.trim="formEditData.bank_account_no"
                @input="inputNumberOnly($event, 'formEditData', 'bank_account_no')"
                type="tel"
                class="form-control"
                placeholder="Bank Account No."
                name="Bank Account No."
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.bank_account_no.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.bank_account_no.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.bank_account_no.required"
                    >Bank Account No. is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12" v-else>
              <label>Bank Account No. (Address)</label>
              <input
                v-model.trim="formEditData.bank_account_no"
                type="text"
                class="form-control"
                placeholder="Bank Account No."
                name="Bank Account No."
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.bank_account_no.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.bank_account_no.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.bank_account_no.required"
                    >Bank Account No. is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>IFSC Code</label>
              <input
                v-model.trim="formEditData.ifsc_code"
                type="text"
                class="form-control"
                placeholder="IFSC Code"
                name="IFSC Code"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.ifsc_code.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.ifsc_code.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.ifsc_code.required"
                    >IFSC Code is required.</span
                    >
              </div>
            </div>
            <!-- <div class="mb-3 col-12 col-md-12">
              <label>Current Bank Balance</label>
              <input
                v-model.trim="formEditData.current_bank_balance"
                @input="inputNumberOnly($event, 'formEditData', 'current_bank_balance')"
                type="text" 
                inputmode="decimal"
                class="form-control"
                placeholder="Current Bank Balance"
                name="Current Bank Balance"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.current_bank_balance.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.current_bank_balance.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.current_bank_balance.required"
                    >Current Bank Balance is required.</span
                    >
              </div>
            </div> -->
            <div class="mb-3 col-12 col-md-12">
              <label>Transaction Limit</label>
              <input
                v-model.trim="formEditData.transaction_limit"
                @input="inputNumberOnly($event, 'formEditData', 'transaction_limit')"
                type="text" 
                inputmode="decimal"
                class="form-control"
                placeholder="Transaction Limit"
                name="Transaction Limit"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.transaction_limit.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.transaction_limit.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.transaction_limit.required"
                    >Transaction Limit is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12 col-md-12">
              <label>Total Lifetime Volume</label>
              <input
                v-model.trim="formEditData.total_volume"
                @input="inputNumberOnly($event, 'formEditData', 'total_volume')"
                type="text" 
                inputmode="decimal"
                class="form-control"
                placeholder="Total Lifetime Volume"
                name="Total Lifetime Volume"
                :class="{
                'is-invalid':
                modalSubmit && $v.formEditData.total_volume.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.formEditData.total_volume.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.formEditData.total_volume.required"
                    >Total lifetime volume is required.</span
                    >
              </div>
            </div>
            <div class="mb-3 col-12">
              <div class="align-items-center">
                <label class="mb-1 me-2">Status</label>
                <div>
                  <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                    <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.status" true-value="1" false-value="0" :disabled="modalLoading">
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-4 col-12 col-md-12">
              <label>Remarks</label>
              <div class="input-group">
                <input
                  v-model.trim="formEditData.remarks"
                  type="text"
                  class="form-control"
                  placeholder="Remarks"
                  name="Remarks"
                />
                <b-dropdown variant="info" right>
                  <template v-slot:button-content>
                    General Remark
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item
                    href="javascript:void(0)"
                    :value="value"
                    @click="formEditData.remarks=value"
                    v-for="(value, index) in remarksList"
                    :key="index"
                    >{{ value }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>

          <!-- <div class="text-muted font-size-12">Created at {{ formEditData.created_at }}</div> -->
          <div class="col-12 d-none">
            <label class="mb-2 me-2">UPI Accounts Linked <i class="bi bi-check-circle text-primary ms-2"></i></label>
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
            <table class="table table-hover align-middle mb-0">
              <thead class="text-uppercase">
                <tr>
                  <th scope="col" class="fw-medium">UPI ID</th>
                  <th scope="col" class="fw-medium">UPI Name</th>
                  <th scope="col" class="fw-medium">Type</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
               
                <tr v-for="(value, index) in formEditData.upi_accounts" :key="'main_'+index">
                  <td>{{ value.upi_id }}</td>
                  <td>{{ value.upi_name }}</td>
                  <td>{{ value.type }}</td>
                </tr>
                <tr v-if="!formEditData.upi_accounts.length && !modalLoading">
                    <td colspan="3" height="200" class="text-center text-muted">
                      <i class="bi bi-credit-card me-1"></i> No Data
                      Available
                    </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
          <div class="col-12">
            <hr>
            <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-edit-group'].hide()">Cancel</button>
                <button type="button" class="btn fw-medium btn-info" @click="updateRequest" :disabled="modalLoading || loading">
                <span v-if="modalLoading"> Updating...</span>
                <span v-else> Update </span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                </button>
            </div>
          </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>

<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { 
    required, 
    //minLength, 
    //url
  } from "vuelidate/lib/validators";

  /**
   * Starter page
   */
  const BankDefault = {};
  export default {
    components: {
      Common,
      Multiselect
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        baseCurrency: appConfig.baseCurrency,
        updateData: {
          preview_username:""
        },
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        create_modify_permission:false,
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        returnData: [],
        returnData2: [],
        isEdit:false,
        banksList:[],
        remarksList:["司法冻结","送回维护","银行冻结","其它"],
        selectedBanks: BankDefault,
        searchKey:"",
        formData: {
          bank_name:"",
          bank_account_name:"",
          bank_account_no:"",
          ifsc_code:"",
          remarks:"",
          current_bank_balance:"",
          transaction_limit:"125000"
        },
        formEditData: {
          id:"",
          bank_name:"",
          bank_account_name:"",
          bank_account_no:"",
          ifsc_code:"",
          status:"",
          remarks:"",
          current_bank_balance:"",
          transaction_limit:"",
          total_volume:"",
          upi_accounts:[]
        },
        currentModules:[]
      };
    },
    validations: {
      formData: {
        bank_name: {
          required,
        },
        bank_account_name: {
          required,
        },
        bank_account_no: {
          required,
        },
        ifsc_code: {
          required,
        },
        current_bank_balance: {
          required,
        },
        transaction_limit: {
          required,
        }
      },
      formEditData: {
        bank_name: {
          required,
        },
        bank_account_name: {
          required,
        },
        bank_account_no: {
          required,
        },
        ifsc_code: {
          required,
        },
        // current_bank_balance: {
        //   required,
        // },
        transaction_limit: {
          required,
        },
        total_volume: {
          required,
        }
      },

    },
    middleware: "authentication",
    async mounted() {
      this.reload();
    },
    created() {
      
    },
    methods: {
      getBankList() {
        console.log("getBankList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        //bodyFormData.append("currency", this.formData.amountToReceiveCurrency.shortName);
        var posturl = ""
        if (this.baseCurrency == 'MYR') {
          posturl = "/api/bank_list_my.json"
        } else if (this.baseCurrency == 'IDR') {
          posturl = "/api/bank_list_id.json"
        } else if (this.baseCurrency == 'VND') {
          posturl = "/api/bank_list_vn.json"
        } else if (this.baseCurrency == "INR") {
          posturl = "/api/bank_list_in.json";
        }else if (this.baseCurrency == "INR_NATIVE") {
          posturl = "/api/bank_list_in.json";
        }else if (this.baseCurrency == "HKD") {
          posturl = "/api/bank_list_hk.json";
        }else {
          this.banksList = []
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          return
        }
        axios({
          method: "get",
          url: posturl,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data"
          },
        }).then((response) => {
          var resData = response.data;
          console.log(resData.result)
          if (resData.status == 200) {
            this.selectedBanks = BankDefault
            this.banksList = [this.selectedBanks]
            const popular = []
            const other = []
            var selectedBank2 = {}
            resData.result.forEach(element => {
              if (element.category === 'popular') {
                popular.push(element);
              } else {
                other.push(element);
              }
              if (this.formEditData.bank_name==element.name){
                selectedBank2 = element
              }
            });
            this.banksList = [{
              category: 'Popular',
              list: popular
            }, {
              category: 'Other',
              list: other
            }]
            if (this.isEdit){
              this.selectedBanks = selectedBank2
            }else{
              this.selectedBanks = this.banksList[0].list[0];
              this.formData.bank_name = this.selectedBanks.name;
            }
          } else if (resData.status == 440) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          }
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
        }).catch((error) => {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        });
      },
      inputNumberOnly(event, parentModel, childModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
      },
      inputNumberOnlyV2(event, parentModel, childModel) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel][childModel] = val;
        }
      },
      inputNumberOnlyV3(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        //this[parentModel][childModel] = numericValue
        this[parentModel].contract_selected[index][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel].contract_selected[index][childModel] = val;
        }
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      showModal() {
        this.$refs['modal-create-group'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.isEdit = false;
        this.getBankList();
      },
      showEditModal() {
        this.$refs['modal-edit-group'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.isEdit = true;
        this.formEditData= {
          id: this.data.updateData.account_db_code,
          bank_name: this.data.updateData.bank_name,
          bank_account_name: this.data.updateData.bank_account_name,
          bank_account_no: this.data.updateData.bank_account_no,
          ifsc_code: this.data.updateData.ifsc_code,
          current_bank_balance: this.data.updateData.current_bank_balance,
          transaction_limit: this.data.updateData.transaction_limit,
          total_volume: this.data.updateData.total_in_amount,
          upi_accounts: this.data.updateData.upi_accounts,
          status: this.data.updateData.active,
          remarks: this.data.updateData.remarks,
          created_at: this.data.updateData.created_at,
        }
        this.getBankList();
      },
      updateBankName(){
        this.formData.bank_name=this.selectedBanks.name,
        this.formEditData.bank_name=this.selectedBanks.name
      },
      showDetails() {
        this.$refs['modal-details'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.returnData = this.updateData.bank_list
      },
      showDetails2() {
        this.$refs['modal-merchants'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.returnData2 = this.updateData.merchants
      },
      resetModal() {
        this.searchKey = "";
        this.returnData = []
        this.returnData2 = []
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData= {
          bank_name:"",
          bank_account_name:"",
          bank_account_no:"",
          ifsc_code:"",
          current_bank_balance:"",
          transaction_limit:"125000",
          remarks:"",
        }
        this.formEditData= {
          id:"",
          bank_name:"",
          bank_account_name:"",
          bank_account_no:"",
          ifsc_code:"",
          current_bank_balance:"",
          transaction_limit:"",
          total_volume:"",
          status:"",
          remarks:"",
          upi_accounts:[]
        }
      },
      addRequest(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        console.log((this.$v.formData.$invalid))
        if (this.$v.formData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("bankName", this.formData.bank_name);
          bodyFormData.append("bankAccName", this.formData.bank_account_name);
          bodyFormData.append("bankAccNo", this.formData.bank_account_no);
          bodyFormData.append("currentBankBalance", this.formData.current_bank_balance);
          bodyFormData.append("transactionLimit", this.formData.transaction_limit);
          bodyFormData.append("ifscCode", this.formData.ifsc_code);
          bodyFormData.append("remarks", this.formData.remarks);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/bankAccount/addBankAccount',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Bank Account Successfully Added',
                  html: 'Your bank account has been successfully added!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-create-group'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateRequest(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        console.log((this.$v.formEditData.$invalid))

        // const combinedArray = this.formEditData.bank_accounts.concat(this.formEditData.bank_accounts_new);

        if (this.$v.formEditData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("accountDbCode", this.formEditData.id);
          bodyFormData.append("bankName", this.formEditData.bank_name);
          bodyFormData.append("bankAccName", this.formEditData.bank_account_name);
          bodyFormData.append("bankAccNo", this.formEditData.bank_account_no);
          bodyFormData.append("ifscCode", this.formEditData.ifsc_code);
          //bodyFormData.append("currentBankBalance", this.formEditData.current_bank_balance);
          bodyFormData.append("transactionLimit", this.formEditData.transaction_limit);
          bodyFormData.append("totalInAmount", this.formEditData.total_volume);
          bodyFormData.append("remarks", this.formEditData.remarks);
          bodyFormData.append("status", this.formEditData.status);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/bankAccount/updateBankAccount',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Bank Account Updated',
                  html: 'Bank account has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-edit-group'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateUPI(value){
        this.$Progress.start();
        this.modalLoading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accountDbCode", this.updateData.account_db_code);
        bodyFormData.append("id", value.id);
        bodyFormData.append("active", value.active);
        axios({
            method: "post",
            //url: appConfig.APIHostAdmin + 'controller/activeInactiveUpiAccount', 
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
          var resData = response.data;
          //this.returnData = []
          if (resData.status == 200) {
            console.log(resData)
            value.active = (value.active==1)? 1 : 0;
            //this.getData();
          } 
          else if (resData.status == 440){
            value.active = (value.active==1)? 0 : 1;
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.modalLoading = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.modalLoading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            const permission = data.modules.filter(e => e.name==='administrator')
            if (permission) {
              this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
              console.log(this.create_modify_permission)
            } else {
              console.log('Module with name "administrator" not found.');
            }
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  };
</script>
<style scoped>
  label{
    margin-bottom: 5px;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
</style>